import _ from "lodash";
import React from "react";
import {
  URL_REGEX,
  MEDIA_TYPE,
  CDN_URL_REGEX,
  ALLOWED_ENCODERS,
  PLAY_RESTRICTIONS,
  SUPPORTED_LANGUAGES,
  SUPPORTED_IMAGE_SIZE,
  SUPPORTED_MEDIA_TYPE,
  SUPPORTED_IMAGE_TYPE,
  DISPLAY_CATEGORY_TYPE,
  SPECIAL_HEADERS_COUNT,
  ALLOWED_MAX_QUALITIES,
  IMAGES_DIRECTORY_PREFIX,
  ALLOWED_MATURITY_RATINGS,
  QALBOX_SUPPORTED_COUNTRY_CODES,
  MEDIA_LABELS,
  SUPPORTED_IMAGE_TYPE_V2,
  DASH_CONFIGS,
  HLS_CONFIGS,
  URL_DASH,
  URL_HLS,
  MEDIA_CDN_LOCATION,
  DASH_FILE_NAME,
  HLS_FILE_NAME,
  SHORTS_CDN_LOCATION,
  SHORTS_HLS_FILE_NAME,
  SHORTS_DASH_FILE_NAME,
} from "./Constants";
import { getDateFromExcelValue } from "../../utils";
import { ENVIRONMENT } from "../../Constants";
import { Tags, URLS, Images, GroupId, CopyItem, TextAlign } from "./common";
import moment from "moment";
import CustomFilter from "../../widgets/qalbox-table/custom-filter";

const isValidMediaType = (type) => {
  return _.includes(_.values(MEDIA_TYPE), type);
};

const isValidDisplayCategoryType = (type) => {
  return _.includes(_.values(DISPLAY_CATEGORY_TYPE), type);
};

const isValidLanguageCode = (languageCode) => {
  return _.includes(SUPPORTED_LANGUAGES, languageCode);
};

const areValidItems = (array, valueArray) => {
  let isValid = true;

  _.forEach(array, (item) => {
    if (!_.includes(valueArray, item)) {
      isValid = false;
      return false;
    }
  });

  return isValid;
};

const isPositiveInteger = (integer) => {
  return _.isInteger(integer) && _.inRange(integer, 0, Number.MAX_SAFE_INTEGER);
};

const isValidSeconds = (seconds) => {
  return _.isInteger(seconds) && _.inRange(seconds, 0, 61);
};

const isValidPlayTime = (playNextAfter) => {
  const [minutes, seconds] = _.split(_.toString(playNextAfter), ":");
  const isValid =
    isPositiveInteger(_.parseInt(minutes)) &&
    isValidSeconds(_.parseInt(seconds));
  const secondsFormatted =
    isValid && _.size(seconds) === 1 ? `0${seconds}` : seconds;
  return isValid ? `${minutes}:${secondsFormatted}` : false;
};

// const isValidURLs = urlsString => {
//   if (!_.isString(urlsString)) {
//     return false;
//   }
//   let validURLs = true;
//
//   const dataArray = getArrayFromString(urlsString);
//   _.forEach(dataArray, dataItem => {
//     if (!URL_REGEX.test(_.trim(dataItem))) {
//       validURLs = false;
//       return false;
//     }
//   });
//
//   return validURLs ? dataArray : false;
// };

const isValidCDNURLs = ({ urlsString, type }) => {
  if (!_.isString(urlsString) || !type) {
    return false;
  }
  let validURLs = true;

  const dataArray = getArrayFromString(urlsString);
  _.forEach(dataArray, (dataItem) => {
    const cdnUrlRegex = getCDNUrlRegex({
      type,
    });
    if (!cdnUrlRegex) {
      validURLs = false;
      return false;
    }

    if (!new RegExp(cdnUrlRegex).test(_.trim(dataItem))) {
      validURLs = false;
      return false;
    }
  });

  return validURLs ? dataArray : false;
};

const isValidURL = (urlsString) => {
  if (!_.isString(urlsString)) {
    return false;
  }

  return URL_REGEX.test(urlsString);
};

const areValidCountryCodes = (countryCodes) => {
  const supportedCountries = _.keys(QALBOX_SUPPORTED_COUNTRY_CODES);
  const uniqueCountryCodes = _.uniq(countryCodes);
  const countryCodesSize = _.size(uniqueCountryCodes);
  const intersection = _.intersection(supportedCountries, countryCodes);
  const intersectionSize = _.size(intersection);

  return countryCodesSize > 0 && countryCodesSize === intersectionSize;
};

const getCountryCodesAsKeyValuePair = () => {
  return _.map(
    {
      "": "NONE",
      ALL: "ALL",
      ...QALBOX_SUPPORTED_COUNTRY_CODES,
    },
    (value, key) => {
      return {
        key,
        value,
      };
    }
  );
};

const getCountryCodesAsLabelValuePair = () => {
  return _.map(
    {
      "": "NONE",
      ALL: "ALL",
      ...QALBOX_SUPPORTED_COUNTRY_CODES,
    },
    (value, key) => {
      return {
        label: value,
        value: key,
      };
    }
  );
};

const getArrayFromString = (str, splitToken = ",") => {
  if (!_.isString(str)) {
    return [];
  }

  return _.map(
    str
      .trim()
      .replace(/(^,)|(,$)/g, "")
      .split(splitToken),
    _.trim
  );
};

const getValidTags = (tags) => {
  return _.filter(
    _.map(tags, (tag) => _.toLower(tag.replace(/[^a-zA-Z0-9_ ]|\s/g, ""))),
    _.size
  );
};

const getSupportedLanguages = () => {
  return _.map(SUPPORTED_LANGUAGES, (languageCode) => {
    return {
      key: languageCode,
      value: languageCode,
    };
  });
};

const convertArrayToObjMap = (array) => {
  return _.map(array, (item) => {
    return {
      key: item,
      value: item,
    };
  });
};

const convertArrayToObjMapWithKey = (array, key) => {
  return _.map(array, (item) => {
    return {
      key: item[key],
      value: item[key],
    };
  });
};

const convertArrayToSelectMediaAccessList = (array) => {
  return _.map(array, (item) => {
    console.log({item})
    return {
      label: (item)? item.label : undefined,
      value: (item)? item.value : undefined,
    };
  });
};

const convertArrayToSelectList = (array) => {
  return _.map(array, (item) => {
    return {
      label: item,
      value: item,
    };
  });
};

const convertArrayToSelectListV2 = (objectArray) => {
  return _.map(objectArray, (item) => {
    return {
      value: item.id,
      label: item.name + "-" + item.type,
    };
  });
};

const convertArrayToSelectListV3 = (objectArray) => {
  return _.map(objectArray, (item) => {
    return {
      value: item.id,
      label: item.title,
    };
  });
};

const convertArrayToObjMapWithLowerCase = (array) => {
  return _.map(array, (value, key) => {
    return {
      key: _.toLower(key),
      value: _.startCase(value),
    };
  });
};

const getSupportedImageTypes = () => {
  return _.map(SUPPORTED_IMAGE_TYPE, (value, key) => {
    return {
      key: _.toLower(key),
      value: _.startCase(value),
    };
  });
};

const getSupportedImageTypesV2 = () => {
  return _.map(SUPPORTED_IMAGE_TYPE_V2, (value, key) => {
    return {
      key: _.toLower(key),
      value: _.startCase(value),
    };
  });
};

const getSupportedEnvironments = () => {
  return _.map(ENVIRONMENT, (value, key) => {
    return {
      key: _.toLower(key),
      value: _.startCase(value),
    };
  });
};

const getSupportedImageSizes = () => {
  return _.map(SUPPORTED_IMAGE_SIZE, (value, key) => {
    return {
      key: _.toLower(key),
      value: _.startCase(value),
    };
  });
};

const getImagesUpdated = ({
  item,
  errors,
  newItem,
  imageKey,
  largeImageURL,
  smallImageURL,
  errorsCount,
  isRequiredForNew,
}) => {
  const imageData = {};
  if (newItem) {
    if (isValidURL(largeImageURL)) {
      imageData["large"] = largeImageURL;
    } else {
      if (isRequiredForNew) {
        errors["errorList"].push(
          `${++errorsCount}. ${_.startCase(
            imageKey
          )} large is invalid and required for new item.`
        );
      }
    }
  } else {
    if (isValidURL(largeImageURL)) {
      imageData["large"] = largeImageURL;
    }
  }

  // Previews Image Small
  if (newItem) {
    if (isValidURL(smallImageURL)) {
      imageData["small"] = smallImageURL;
    } else {
      if (isRequiredForNew) {
        errors["errorList"].push(
          `${++errorsCount}. ${_.startCase(
            imageKey
          )} small invalid and required for new item.`
        );
      }
    }
  } else {
    if (isValidURL(smallImageURL)) {
      imageData["small"] = smallImageURL;
    }
  }

  if (imageData.large && imageData.small) {
    item[imageKey] = imageData;
  } else {
    if (
      (imageData.large && !imageData.small) ||
      (imageData.small && !imageData.large)
    ) {
      errors["errorList"].push(
        `${++errorsCount}. Both ${_.startCase(
          imageKey
        )} small and large images are required when add or update.`
      );
    }
  }

  return errorsCount;
};

const updateDate = ({
  date,
  item,
  errors,
  newItem,
  dateKey,
  errorsCount,
  isRequiredForNew = true,
  useCurrentDateIfNotFound = false,
}) => {
  // console.log('isRequiredForNew : ', isRequiredForNew)
  // console.log('dateKey : ', dateKey)
  // console.log('date : ', date)
  // console.log('excelDate : ', getDateFromExcelValue(date))
  if (newItem) {
    if (_.isNil(date)) {
      if (isRequiredForNew) {
        if (useCurrentDateIfNotFound) {
          item[dateKey] = new Date();
        } else {
          errors["errorList"].push(
            `${++errorsCount}. ${_.startCase(dateKey)} is missing.`
          );
        }
      }
    } else {
      item[dateKey] = getDateFromExcelValue(date);
    }
  } else {
    if (!_.isNil(date)) {
      item[dateKey] = getDateFromExcelValue(date);
    }
  }

  console.log("#######");
  return errorsCount;
};

const getMediaItems = async (rows) => {
  const items = [];
  const allErrors = [];
  // Starting from SPECIAL_HEADERS_COUNT, because we need to skip title and comments.
  for (let i = SPECIAL_HEADERS_COUNT; i < rows.length; i++) {
    let errorsCount = 0;
    const data = rows[i];
    let newItem = true;

    const item = {};

    const errors = {
      row: i + 1,
      errorsCount: 0,
      errorList: [],
      excelSheetRowData: data,
    };
    console.log("Validation row : ", i + 1);

    // 1. Media Type
    const type = data[0] || null;
    if (type) {
      if (isValidMediaType(type)) {
        item["type"] = type;
      } else {
        errors["errorList"].push(`${++errorsCount}. Media type is invalid.`);
      }
    } else {
      errors["errorList"].push(`${++errorsCount}.  Media type  not found.`);
    }
    // console.log('type : ', item['type']);

    // 2. Media Id
    const mediaId = data[1];
    if (mediaId) {
      item["id"] = mediaId;
      newItem = false;
    }
    // console.log('id : ', item['id']);

    // 3. Language Code
    const languageCode = _.toLower(data[2]);
    if (languageCode) {
      if (isValidLanguageCode(languageCode)) {
        item["language"] = languageCode;
      } else {
        errors["errorList"].push(`${++errorsCount}. Language code is invalid.`);
      }
    } else {
      errors["errorList"].push(`${++errorsCount}. Language Code not found.`);
    }
    // console.log('languageCode : ', item['language']);

    // 4. Title
    const title = data[3];
    if (newItem) {
      if (title) {
        item["title"] = title;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Title is required for new item.`
        );
      }
    } else {
      if (title) {
        item["title"] = title;
      }
    }
    // console.log('title : ', item['title']);

    // 5. Description
    const description = data[4];
    if (newItem) {
      if (description) {
        item["description"] = description;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Description is required for new item.`
        );
      }
    } else {
      if (description) {
        item["description"] = description;
      }
    }
    // console.log('description : ', item['description']);

    // 46. Upcoming
    const upcoming = data[45];
    // console.log('##### upcoming : ', upcoming);
    if (!_.isNil(upcoming)) {
      if (_.isBoolean(upcoming)) {
        item["configs"] = {
          upcoming,
        };
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Invalid value for Upcoming.`
        );
      }
    }
    const isUpComingMedia = _.get(item, "configs.upcoming", false);

    // URLs
    const urls = [];

    // 6. URL - HLS
    const hlsUrl = data[5];
    const urlHls = {
      type: SUPPORTED_MEDIA_TYPE.HLS,
      mimeType: "application/x-mpegURL",
      configs: {
        keySystems: {
          "com.apple.fps.1_0": {
            certificateUri:
              "https://fp-keyos.licensekeyserver.com/cert/07fbcb8b4cdad9d3c864b4a08585fcc5.der",
            licenceUrl: "https://fp-keyos.licensekeyserver.com/getkey",
          },
        },
      },
    };
    if (newItem) {
      if (hlsUrl) {
        const validUrl = isValidCDNURLs({
          urlsString: hlsUrl,
          type: SUPPORTED_MEDIA_TYPE.HLS,
        });
        if (validUrl) {
          urlHls["src"] = hlsUrl;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. HLS URL is invalid for new Media Item.`
          );
        }
      } else {
        if (MEDIA_TYPE.SERIES !== item["type"]) {
          if (!isUpComingMedia) {
            errors["errorList"].push(
              `${++errorsCount}. HLS URL is required for new movie or episode items.`
            );
          }
        }
      }
    } else {
      if (hlsUrl) {
        const validUrl = isValidCDNURLs({
          urlsString: hlsUrl,
          type: SUPPORTED_MEDIA_TYPE.HLS,
        });
        if (validUrl) {
          urlHls["src"] = hlsUrl;
        } else {
          errors["errorList"].push(`${++errorsCount}. HLS URL is invalid.`);
        }
      }
    }

    // 7. URL - HLS - Encoder
    const hlsEncoder = data[6];
    if (hlsEncoder) {
      if (_.includes(ALLOWED_ENCODERS, hlsEncoder)) {
        urlHls["encoder"] = hlsEncoder;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Invalid value for HLS Encoder. Accepted values : ${_.join(
            ALLOWED_ENCODERS,
            ","
          )}`
        );
      }
    }

    // Add HLS Url if it is available
    if (urlHls["src"]) {
      urls.push(urlHls);
    }

    // 8. URL - DASH
    const dashUrl = data[7];
    const urlDash = {
      type: SUPPORTED_MEDIA_TYPE.DASH,
      mimeType: "application/dash+xml",
      configs: {
        keySystems: {
          "com.widevine.alpha": {
            certificateUri: "https://wv-keyos.licensekeyserver.com/",
          },
          "com.microsoft.playready": {
            certificateUri:
              "https://pr-keyos.licensekeyserver.com/core/rightsmanager.asmx",
          },
        },
      },
    };
    if (newItem) {
      if (dashUrl) {
        const validUrl = isValidCDNURLs({
          urlsString: dashUrl,
          type: SUPPORTED_MEDIA_TYPE.DASH,
        });
        if (validUrl) {
          urlDash["src"] = dashUrl;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. DASH URL is invalid for new Media Item.`
          );
        }
      } else {
        if (MEDIA_TYPE.SERIES !== item["type"]) {
          if (!isUpComingMedia) {
            errors["errorList"].push(
              `${++errorsCount}. DASH URL is required for new movie or episode items.`
            );
          }
        }
      }
    } else {
      if (dashUrl) {
        const validUrl = isValidCDNURLs({
          urlsString: dashUrl,
          type: SUPPORTED_MEDIA_TYPE.DASH,
        });
        if (validUrl) {
          urlDash["src"] = dashUrl;
        } else {
          errors["errorList"].push(`${++errorsCount}. DASH URL is invalid.`);
        }
      }
    }

    // 9. URL - DASH - Encoder
    const dashEncoder = data[8];
    if (dashEncoder) {
      if (_.includes(ALLOWED_ENCODERS, dashEncoder)) {
        urlDash["encoder"] = dashEncoder;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Invalid value for Dash Encoder. Accepted values : ${_.join(
            ALLOWED_ENCODERS,
            ","
          )}`
        );
      }
    }

    // Add DASH Url if it is available
    if (urlDash["src"]) {
      urls.push(urlDash);
    }

    // Add urls to item
    item["urls"] = urls;
    console.log("urls : ", item["urls"]);

    // 10. Duration
    const duration = data[9];
    // console.log('duration : ', duration)
    if (duration) {
      const validDuration = isValidPlayTime(duration);
      if (validDuration) {
        item["duration"] = validDuration;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Duration value is invalid.`
        );
      }
    } else {
      if (MEDIA_TYPE.SERIES !== item["type"] && newItem) {
        if (!isUpComingMedia) {
          errors["errorList"].push(
            `${++errorsCount}. Duration value is required for new movie or episode items.`
          );
        }
      }
    }

    // console.log('duration : ', item['duration']);

    // 11. Play Next After
    const playNextAfter = data[10];
    if (playNextAfter) {
      const validPlayNextAfter = isValidPlayTime(playNextAfter);
      if (validPlayNextAfter) {
        item["play_next_after"] = validPlayNextAfter;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Play next after value is invalid.`
        );
      }
    }
    // console.log('playNextAfter : ', item['play_next_after']);

    // Previews

    // URLs
    const previews = [];

    // 12. Preview 1 - HLS
    const previewHlsUrl = data[11];
    // console.log('previewHlsUrl : ', previewHlsUrl);
    const previewUrlHls = {
      order: 1,
      type: SUPPORTED_MEDIA_TYPE.HLS,
      mimeType: "application/x-mpegURL",
      // configs: {
      //   keySystems: {
      //     'com.apple.fps.1_0': {
      //       certificateUri: 'https://fp-keyos.licensekeyserver.com/cert/07fbcb8b4cdad9d3c864b4a08585fcc5.der',
      //       licenceUrl: 'https://fp-keyos.licensekeyserver.com/getkey',
      //     },
      //   },
      // },
    };
    if (newItem) {
      if (previewHlsUrl) {
        const validUrl = isValidCDNURLs({
          urlsString: previewHlsUrl,
          type: SUPPORTED_MEDIA_TYPE.HLS,
        });
        if (validUrl) {
          previewUrlHls["src"] = previewHlsUrl;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Preview HLS URL is invalid for New Media Item.`
          );
        }
      }
    } else {
      if (previewHlsUrl) {
        const validUrl = isValidCDNURLs({
          urlsString: previewHlsUrl,
          type: SUPPORTED_MEDIA_TYPE.HLS,
        });
        if (validUrl) {
          previewUrlHls["src"] = previewHlsUrl;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Preview HLS URL is invalid.`
          );
        }
      }
    }

    // 13. Preview 1 - HLS - Encoder
    const previewHlsEncoder = data[12];
    if (previewHlsEncoder) {
      if (_.includes(ALLOWED_ENCODERS, previewHlsEncoder)) {
        previewUrlHls["encoder"] = previewHlsEncoder;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Invalid value for Preview HLS Encoder. Accepted values : ${_.join(
            ALLOWED_ENCODERS,
            ","
          )}`
        );
      }
    }

    // Add HLS Url if it is available
    if (previewUrlHls["src"]) {
      previews.push(previewUrlHls);
    }

    // 14. Preview 1 - DASH
    const previewDashUrl = data[13];
    const previewUrlDash = {
      order: 1,
      type: SUPPORTED_MEDIA_TYPE.DASH,
      mimeType: "application/dash+xml",
      // configs: {
      //   keySystems: {
      //     'com.widevine.alpha': {
      //       certificateUri: 'https://wv-keyos.licensekeyserver.com/',
      //     },
      //     'com.microsoft.playready': {
      //       certificateUri: 'https://pr-keyos.licensekeyserver.com/core/rightsmanager.asmx',
      //     },
      //   },
      // },
    };
    if (newItem) {
      if (previewDashUrl) {
        const validUrl = isValidCDNURLs({
          urlsString: previewDashUrl,
          type: SUPPORTED_MEDIA_TYPE.DASH,
        });
        if (validUrl) {
          previewUrlDash["src"] = previewDashUrl;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Preview DASH URL is invalid for New media Item.`
          );
        }
      }
    } else {
      if (previewDashUrl) {
        const validUrl = isValidCDNURLs({
          urlsString: previewDashUrl,
          type: SUPPORTED_MEDIA_TYPE.DASH,
        });
        if (validUrl) {
          previewUrlDash["src"] = previewDashUrl;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Preview DASH URL is invalid.`
          );
        }
      }
    }

    // 9. URL - DASH - Encoder
    const previewDashEncoder = data[14];
    if (previewDashEncoder) {
      if (_.includes(ALLOWED_ENCODERS, previewDashEncoder)) {
        previewUrlDash["encoder"] = previewDashEncoder;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Invalid value for Preview Dash Encoder. Accepted values : ${_.join(
            ALLOWED_ENCODERS,
            ","
          )}`
        );
      }
    }

    // Add DASH Url if it is available
    if (previewUrlDash["src"]) {
      previews.push(previewUrlDash);
    }

    // Add urls to item
    item["previews"] = previews;
    console.log("previews : ", item["previews"]);

    // 16. Preview Image Large
    // 17. Preview Image Small
    errorsCount = getImagesUpdated({
      imageKey: "preview_image",
      largeImageURL: data[15],
      smallImageURL: data[16],
      newItem,
      errors,
      errorsCount,
      item,
      isRequiredForNew: true,
    });

    // 18. Thumbnail Image Large
    // 19. Thumbnail Image Small
    errorsCount = getImagesUpdated({
      imageKey: "thumbnail_image",
      largeImageURL: data[17],
      smallImageURL: data[18],
      newItem,
      errors,
      errorsCount,
      item,
      isRequiredForNew: true,
    });

    // 20. Title Image Large
    // 21. Title Image Small
    errorsCount = getImagesUpdated({
      imageKey: "title_image",
      largeImageURL: data[19],
      smallImageURL: data[20],
      newItem,
      errors,
      errorsCount,
      item,
      isRequiredForNew: false,
    });

    // Series Data
    if (item["type"] === MEDIA_TYPE.SERIES) {
      // 22. Series Id
      const seriesId = data[21];
      if (newItem) {
        if (seriesId) {
          item["series_meta_series_id"] = seriesId;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Series id is required for new item of media type series.`
          );
        }
      } else {
        if (seriesId) {
          item["series_meta_series_id"] = seriesId;
        }
      }

      // 23. Seasons Count
      const seasonsCount = data[22];
      if (newItem) {
        if (seasonsCount) {
          item["series_meta_seasons_count"] = seasonsCount;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Series count is required for new item of media type series.`
          );
        }
      } else {
        if (seasonsCount) {
          item["series_meta_seasons_count"] = seasonsCount;
        }
      }
    }

    // Episode Data
    if (item["type"] === MEDIA_TYPE.EPISODE) {
      // 22. Series Id
      const seriesId = data[21];
      if (newItem) {
        if (seriesId) {
          item["series_meta_series_id"] = seriesId;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Series id is required for new item of media type episode.`
          );
        }
      } else {
        if (seriesId) {
          item["series_meta_series_id"] = seriesId;
        }
      }

      // 24. Series Season Id
      const seasonId = data[23];
      if (newItem) {
        if (seasonId) {
          item["series_meta_season_id"] = seasonId;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Season id is required for new item of media type episode.`
          );
        }
      } else {
        if (seasonId) {
          item["series_meta_season_id"] = seasonId;
        }
      }

      // 25. Series Season Episode Id
      const seasonEpisodeId = data[24];
      if (newItem) {
        if (seasonEpisodeId) {
          item["series_meta_episode_id"] = seasonEpisodeId;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Series count is required for new item of media type episode.`
          );
        }
      } else {
        if (seasonEpisodeId) {
          item["series_meta_episode_id"] = seasonEpisodeId;
        }
      }
    }

    // Episode Data
    if (item["type"] === MEDIA_TYPE.MOVIE) {
      // 26. Collection Id
      const collectionId = data[25];
      if (collectionId) {
        item["collections_meta_collection_id"] = collectionId;
      }

      if (collectionId) {
        // 27. Collection Order Id
        const collectionOrder = data[26];
        if (newItem) {
          if (collectionOrder) {
            item["collections_meta_collection_order_id"] = collectionOrder;
          } else {
            errors["errorList"].push(
              `${++errorsCount}. Collection order is required for new item of media type movie.`
            );
          }
        } else {
          if (collectionOrder) {
            item["collections_meta_collection_order_id"] = collectionOrder;
          }
        }
      }
    }

    // 28. Is Featured
    const isFeatured = data[27];
    // console.log('##### isFeatured : ', isFeatured);
    if (newItem) {
      if (!_.isNil(isFeatured)) {
        if (_.isBoolean(isFeatured)) {
          item["featured"] = isFeatured;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Invalid value for Is Featured.`
          );
        }
      } else {
        item["featured"] = false;
      }
    } else {
      if (!_.isNil(isFeatured)) {
        if (_.isBoolean(isFeatured)) {
          item["featured"] = isFeatured;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Invalid value for Is Featured.`
          );
        }
      }
    }

    // Featured Data.
    if (item["featured"]) {
      // 29. Featured From
      const featureStartDate = data[28];
      if (_.isNil(featureStartDate)) {
        errors["errorList"].push(`${++errorsCount}. Featured From is missing.`);
      } else {
        const formattedDate = _.toString(
          getDateFromExcelValue(featureStartDate)
        );
        if (!_.isNumber(featureStartDate) || formattedDate === "Invalid Date") {
          errors["errorList"].push(
            `${++errorsCount}. Featured From is Invalid.`
          );
        } else {
          item["featured_from"] = getDateFromExcelValue(featureStartDate);
        }
      }

      // 30. Featured To
      const featureEndDate = data[29];
      if (_.isNil(featureEndDate)) {
        errors["errorList"].push(`${++errorsCount}. Featured To is missing.`);
      } else {
        const formattedDate = _.toString(getDateFromExcelValue(featureEndDate));
        if (!_.isNumber(featureEndDate) || formattedDate === "Invalid Date") {
          errors["errorList"].push(`${++errorsCount}. Featured To is Invalid.`);
        } else {
          item["featured_to"] = getDateFromExcelValue(featureEndDate);
        }
      }

      // 31. Featured Image Large
      // 32. Feature Image Small
      errorsCount = getImagesUpdated({
        imageKey: "featured_image",
        largeImageURL: data[30],
        smallImageURL: data[31],
        newItem,
        errors,
        errorsCount,
        item,
      });
    }

    // 33. Max Video Quality
    const maxVideoQuality = data[32];
    if (maxVideoQuality) {
      if (_.includes(ALLOWED_MAX_QUALITIES, maxVideoQuality)) {
        item["max_quality"] = maxVideoQuality;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Invalid value for Max Video Quality. Accepted values : ${_.join(
            ALLOWED_MAX_QUALITIES,
            ","
          )}`
        );
      }
    }

    // 34. Maturity Rating
    const maturityRating = data[33];
    // console.log('maturityRating : ', maturityRating);
    if (maturityRating) {
      if (_.includes(ALLOWED_MATURITY_RATINGS, maturityRating)) {
        item["maturity_rating"] = maturityRating;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Invalid value for Maturity Rating. Accepted values : ${_.join(
            ALLOWED_MATURITY_RATINGS,
            ","
          )}`
        );
      }
    }

    // 35. Tags
    const tagString = data[34];
    if (tagString) {
      const tagsArray = getArrayFromString(tagString);
      if (!_.isEmpty(tagsArray)) {
        item["tags"] = getValidTags(tagsArray);
      }
    }

    // 36. Genres
    const genresString = data[35];
    if (genresString) {
      const genresArray = getArrayFromString(genresString);
      if (!_.isEmpty(genresArray)) {
        item["genres"] = genresArray;
      }
    }

    // 37. Directors
    const directorsString = data[36];
    if (directorsString) {
      const directorsArray = getArrayFromString(directorsString);
      if (!_.isEmpty(directorsArray)) {
        item["directors"] = directorsArray;
      }
    }

    // 38. Cast
    const castString = data[37];
    if (castString) {
      const castArray = getArrayFromString(castString);
      if (!_.isEmpty(castArray)) {
        item["cast"] = castArray;
      }
    }

    // 39. Countries Allowed
    const countriesAllowedString = data[38];
    console.log("countriesAllowedString : ", countriesAllowedString);
    const countriesAllowed = getArrayFromString(countriesAllowedString);
    if (!_.isEmpty(countriesAllowed)) {
      if (_.size(countriesAllowed) > 1 && _.includes(countriesAllowed, "ALL")) {
        errors["errorList"].push(
          `${++errorsCount}. Invalid to combine ALL option and Other Countries.`
        );
      } else {
        const validCountryCodes = areValidCountryCodes(countriesAllowed);
        if (validCountryCodes) {
          item["countries_allowed"] = countriesAllowed;
          item["countries_disallowed"] = ["ALL"];
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Allowed country code list contain one or many invalid country codes.`
          );
        }
      }
    }

    // 40. Countries Disallowed
    const countriesDisAllowedString = data[39];
    console.log("countriesDisAllowedString : ", countriesDisAllowedString);
    const countriesDisAllowed = getArrayFromString(countriesDisAllowedString);
    if (_.isEmpty(countriesDisAllowed)) {
      item["countries_disallowed"] = [];
    } else {
      if (_.size(item["countries_allowed"]) > 0) {
        errors["errorList"].push(
          `${++errorsCount}. Cannot combine countries allowed and disallowed.`
        );
      } else {
        const validCountryCodes = areValidCountryCodes(countriesDisAllowed);
        if (validCountryCodes) {
          item["countries_disallowed"] = countriesDisAllowed;
          item["countries_allowed"] = ["ALL"];
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Not allowed country code list contain one or many invalid country codes.`
          );
        }
      }
    }

    // Update countries_allowed if countries_disallowed or countries_allowed not found.
    if (
      _.isEmpty(item["countries_disallowed"]) &&
      _.isEmpty(item["countries_allowed"])
    ) {
      item["countries_allowed"] = ["ALL"];
    }

    // 41. Released Date
    errorsCount = updateDate({
      date: data[40],
      item,
      errors,
      newItem,
      dateKey: "released_date",
      errorsCount,
      isRequiredForNew: false,
      useCurrentDateIfNotFound: false,
    });

    // Released Year.
    if (item["released_date"]) {
      item["released_year"] = item["released_date"].getFullYear();
    }

    // 42. Added Date
    errorsCount = updateDate({
      date: data[41],
      item,
      errors,
      newItem,
      dateKey: "added_date",
      errorsCount,
      isRequiredForNew: false,
      useCurrentDateIfNotFound: true,
    });

    // Added Year.
    if (item["added_date"]) {
      item["added_year"] = item["added_date"].getFullYear();
    }

    // 43. License Termination
    errorsCount = updateDate({
      date: data[42],
      item,
      errors,
      newItem,
      dateKey: "licence_termination",
      errorsCount,
      isRequiredForNew: false,
      useCurrentDateIfNotFound: false,
    });

    // 44. License Renewal
    errorsCount = updateDate({
      date: data[43],
      item,
      errors,
      newItem,
      dateKey: "licence_renewal",
      errorsCount,
      isRequiredForNew: false,
      useCurrentDateIfNotFound: false,
    });

    // 45. License Reminder
    errorsCount = updateDate({
      date: data[44],
      item,
      errors,
      newItem,
      dateKey: "licence_renewal_reminder",
      errorsCount,
      isRequiredForNew: false,
      useCurrentDateIfNotFound: false,
    });

    // 47. Play Restrictions
    const playRestrictionsString = data[46];
    const playRestrictions = getArrayFromString(playRestrictionsString);
    if (!_.isEmpty(playRestrictions)) {
      const validPlayRestrictions = areValidItems(
        playRestrictions,
        PLAY_RESTRICTIONS
      );
      if (validPlayRestrictions) {
        item["play_restrictions"] = playRestrictions;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Play restrictions contains invalid values. Accepted values : ${_.join(
            PLAY_RESTRICTIONS,
            ", "
          )}`
        );
      }
    } else {
      errors["errorList"].push(
        `${++errorsCount}. Play restrictions can't be empty. Accepted values : ${_.join(
          PLAY_RESTRICTIONS,
          ", "
        )}`
      );
    }

    // 48. Featured Pages
    const featuredPagesString = data[47];
    const featuredPages = getArrayFromString(featuredPagesString);
    if (!_.isEmpty(featuredPages)) {
      const validFeaturedPages = areValidItems(
        featuredPages,
        DISPLAY_CATEGORY_TYPE
      );
      if (validFeaturedPages) {
        item["featured_pages"] = featuredPages;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Featured Pages contains invalid values. Accepted values : ${_.join(
            DISPLAY_CATEGORY_TYPE,
            ", "
          )}`
        );
      }
    } else {
      item["featured_pages"] = [""];
    }

    // 49. Featured Media Order
    const featuredMediaOrder = data[48];
    if (featuredMediaOrder) {
      try {
        item["featured_media_order"] = parseInt(featuredMediaOrder);
      } catch (e) {
        errors["errorList"].push(
          `${++errorsCount}. Featured Media Order contains invalid values. Accepted values : Numbers)}`
        );
      }
    } else {
      item["featured_media_order"] = [""];
    }

    // 50. Media Labels (New, Weekly, Top 10)
    const mediaLabelsString = data[49];
    const mediaLabels = getArrayFromString(mediaLabelsString);
    if (!_.isEmpty(mediaLabels)) {
      const validMediaLabels = areValidItems(mediaLabels, MEDIA_LABELS);
      if (validMediaLabels) {
        item["media_labels"] = mediaLabels;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Media Labels contains invalid values. Accepted values : ${_.join(
            MEDIA_LABELS,
            ", "
          )}`
        );
      }
    }

    items.push(item);
  }

  console.log("All Items : ", items);
  console.log("All Errors : ", allErrors);

  return {
    items,
    allErrors,
  };
};

const getDisplayCategoryItems = async (rows) => {
  const items = [];
  const allErrors = [];
  // Starting from SPECIAL_HEADERS_COUNT, because we need to skip title and comments.
  for (let i = SPECIAL_HEADERS_COUNT; i < rows.length; i++) {
    let errorsCount = 0;
    const data = rows[i];
    let newItem = true;

    const item = {};

    const errors = {
      row: i + 1,
      errorsCount: 0,
      errorList: [],
      excelSheetRowData: data,
    };
    console.log("Validation row : ", i + 1);

    // Media Id
    const id = data[1];
    if (id) {
      item["id"] = id;
      newItem = false;
    }
    // console.log('id : ', item['id']);

    // Name
    const name = data[0] || null;
    if (newItem) {
      if (name) {
        item["name"] = name;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Name is required for new item.`
        );
      }
    } else {
      if (name) {
        item["name"] = name;
      }
    }
    // console.log('name : ', item['name']);

    // Language Code
    const languageCode = _.toLower(data[2]);
    if (languageCode) {
      if (isValidLanguageCode(languageCode)) {
        item["language"] = languageCode;
      } else {
        errors["errorList"].push(`${++errorsCount}. Language code is invalid.`);
      }
    } else {
      errors["errorList"].push(`${++errorsCount}. Language Code not found.`);
    }
    // console.log('languageCode : ', item['language']);

    // Type
    const type = data[3];
    if (newItem) {
      if (isValidDisplayCategoryType(type)) {
        item["type"] = type;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Type is invalid and required for new item.`
        );
      }
    } else {
      if (type) {
        item["type"] = type;
      }
    }
    // console.log('type : ', item['type']);

    // Order
    const order = data[4];
    if (newItem) {
      if (isPositiveInteger(order)) {
        item["order"] = order;
      } else {
        errors["errorList"].push(
          `${++errorsCount}. Order is invalid and required for new item.`
        );
      }
    } else {
      if (order) {
        item["order"] = order;
      }
    }
    // console.log('type : ', item['type']);

    // Active
    const active = data[5];
    if (newItem) {
      if (!_.isNil(active)) {
        if (_.isBoolean(active)) {
          item["active"] = active;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Invalid value for Active.`
          );
        }
      } else {
        item["active"] = true;
      }
    } else {
      if (!_.isNil(active)) {
        if (_.isBoolean(active)) {
          item["active"] = active;
        } else {
          errors["errorList"].push(
            `${++errorsCount}. Invalid value for Active.`
          );
        }
      }
    }
    // console.log('active : ', item['active']);

    // Tags
    const tagString = data[6];
    if (tagString) {
      const tagsArray = getArrayFromString(tagString);
      if (!_.isEmpty(tagsArray)) {
        item["tags"] = getValidTags(tagsArray);
      }
    }

    // Genres
    const genresString = data[7];
    if (genresString) {
      const genresArray = getArrayFromString(genresString);
      if (!_.isEmpty(genresArray)) {
        item["genres"] = genresArray;
      }
    }

    // Countries Allowed
    const countriesAllowedString = data[8];
    console.log("countriesAllowedString : ", countriesAllowedString);
    const countriesAllowed = getArrayFromString(countriesAllowedString);
    if (!_.isEmpty(countriesAllowed)) {
      if (_.size(countriesAllowed) > 1 && _.includes(countriesAllowed, "ALL")) {
        errors["errorList"].push(
          `${++errorsCount}. Invalid to combine ALL option and Other Countries.`
        );
      } else {
        item["countries_allowed"] = countriesAllowed;
      }
    }

    // Countries Disallowed
    const countriesDisAllowedString = data[9];
    console.log("countriesDisAllowedString : ", countriesDisAllowedString);
    const countriesDisAllowed = getArrayFromString(countriesDisAllowedString);
    if (_.isEmpty(countriesDisAllowed)) {
      item["countries_disallowed"] = [];
    } else {
      if (_.size(item["countries_allowed"]) > 0) {
        errors["errorList"].push(
          `${++errorsCount}. Cannot combine countries allowed and disallowed.`
        );
      } else {
        item["countries_disallowed"] = countriesDisAllowed;
      }
    }

    // Update countries_allowed if countries_disallowed or countries_allowed not found.
    if (
      _.isEmpty(item["countries_disallowed"]) &&
      _.isEmpty(item["countries_allowed"])
    ) {
      item["countries_allowed"] = ["ALL"];
    }

    errors["errorsCount"] = errorsCount;
    if (errors["errorsCount"] > 0) {
      allErrors.push(errors);
    }
    items.push(item);
  }

  console.log("All Items : ", items);
  console.log("All Errors : ", allErrors);

  return {
    items,
    allErrors,
  };
};

const getDisplayCopyItems = (displayItemsData) => {
  console.log("displayItemsData : ", displayItemsData);
  return JSON.stringify(displayItemsData);
};

const getMediaCopyItems = (displayItemsData) => {
  console.log("getMediaCopyItems : ", displayItemsData);
  return JSON.stringify(displayItemsData);
};

const getFileUploadPath = ({
  fileName,
  selectedLanguage,
  selectedImageSize,
  selectedImageType,
}) => {
  return `/${IMAGES_DIRECTORY_PREFIX}/${selectedLanguage}/${selectedImageType}/${selectedImageSize}/${fileName}`;
};

const getUploadableFiles = async ({
  files,
  selectedLanguage,
  selectedImageType,
  selectedImageSize,
}) => {
  const uploadableFiles = [];

  for (const file of files) {
    console.log(file);
    const encodedImage = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(e.target.result);
      fileReader.readAsDataURL(file);
    });

    const isPngImage = file.type === "image/png";

    console.log("encodedImage : ", encodedImage);
    const base64EncodedImage = isPngImage
      ? encodedImage.replace("data:image/png;base64,", "")
      : encodedImage.replace("data:image/jpeg;base64,", "");

    // More info about adding meta data :
    // https://cloud.google.com/storage/docs/viewing-editing-metadata#storage-view-object-metadata-nodejs
    // https://cloud.google.com/storage/docs/uploading-objects
    const metadata = {
      contentType: `${isPngImage ? "image/png" : "image/jpeg"}`,
      cacheControl: "public, max-age=31536000",
    };

    const fileUploadPath = getFileUploadPath({
      fileName: file.name,
      selectedLanguage,
      selectedImageType,
      selectedImageSize,
    });

    console.log("base64EncodedImage : ", base64EncodedImage);
    console.log("metadata : ", metadata);

    uploadableFiles.push({
      fileBuffer: base64EncodedImage,
      options: {
        metadata,
      },
      fileType: "image",
      fileUploadPath,
      makePublic: true, // This is options, default is makePublic: true, kept as a API reference.
      clear_cdn_cache: true,
    });
  }

  return {
    uploadableFiles,
  };
};

const getCDNUrlRegex = ({ type }) => {
  if (type === SUPPORTED_MEDIA_TYPE.HLS) {
    return CDN_URL_REGEX.HLS;
  } else if (type === SUPPORTED_MEDIA_TYPE.DASH) {
    return CDN_URL_REGEX.DASH;
  } else {
    return null;
  }
};

const getDisplayCategoryColumns = () => {
  return React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        defaultCanSort: true,
        defaultCanFilter: false,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Name",
        accessor: "name",
        defaultCanSort: true,
        defaultCanFilter: true,
        Filter: CustomFilter,
        width: 200,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Language",
        accessor: "language",
        defaultCanSort: true,
        defaultCanFilter: false,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Type",
        accessor: "type",
        defaultCanSort: true,
        defaultCanFilter: true,
        Filter: CustomFilter,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Order",
        accessor: "order",
        defaultCanSort: true,
        defaultCanFilter: false,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Active",
        accessor: "active",
        defaultCanSort: true,
        defaultCanFilter: false,
        width: 40,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Tags",
        accessor: "tags",
        defaultCanSort: true,
        defaultCanFilter: true,
        Filter: CustomFilter,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Genres",
        accessor: "genres",
        defaultCanSort: true,
        canFilter: false,
        defaultCanFilter: false,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Countries Allowed",
        accessor: "countries_allowed",
        defaultCanSort: true,
        defaultCanFilter: false,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Countries Disallowed",
        accessor: "countries_disallowed",
        defaultCanSort: true,
        defaultCanFilter: false,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Is Branded Partner Rail",
        accessor: "is_branded_partner_rail",
        defaultCanSort: true,
        defaultCanFilter: false,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Branded Partner UUID",
        accessor: "branded_partner_uuid",
        defaultCanSort: true,
        defaultCanFilter: false,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Rail Content Type",
        accessor: "rail_content_type",
        defaultCanSort: true,
        defaultCanFilter: false,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      ,
      {
        Header: "Play Restrictions",
        accessor: "play_restrictions",
        defaultCanSort: true,
        defaultCanFilter: false,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
    ],
    []
  );
};

const getMediaItemsColumnsV2 = () => {
  return React.useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Title",
      accessor: "title",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Primary Details",
      accessor: "primaryDetailsModal",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Images",
      accessor: "imagesModal",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Featured",
      accessor: "featuredModal",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Metadata",
      accessor: "metadataModal",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Ad Units",
      accessor: "avodModal",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
  ]);
};

const getQalboxShortColumns = () => {
  return React.useMemo(() => [
    {
      Header: "UUID",
      accessor: "uuid",
      disableSortBy: true,
      disableFilters: true,
      width: 300,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Title",
      accessor: "title",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Maturity Rating",
      accessor: "maturity_rating",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Max Quality",
      accessor: "max_quality",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Released Year",
      accessor: "released_year",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Tags",
      accessor: "tags",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Genres",
      accessor: "genres",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "URLs",
      accessor: "urls",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Duration",
      accessor: "duration",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Media Labels",
      accessor: "media_labels",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Thumbnail Image",
      accessor: "thumbnail_image",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
    {
      Header: "Main Media UUID",
      accessor: "media_uuid",
      disableSortBy: true,
      disableFilters: true,
      width: 200,
      className:
        "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
    },
  ]);
};

const getMediaItemsColumns = () => {
  return React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        width: 200,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Type",
        accessor: "type",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Language",
        accessor: "language",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Title",
        accessor: "title",
        disableSortBy: true,
        disableFilters: true,
        width: 200,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Description",
        accessor: "description",
        disableSortBy: true,
        disableFilters: true,
        width: 300,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Featured",
        accessor: "featured",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Featured From",
        accessor: "featured_from",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Featured To",
        accessor: "featured_to",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Urls",
        accessor: "urls",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Previews",
        accessor: "previews",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Maturity Rating",
        accessor: "maturity_rating",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Max Quality",
        accessor: "max_quality",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Tags",
        accessor: "tags",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Genres",
        accessor: "genres",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Countries Allowed",
        accessor: "countries_allowed",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Countries Disallowed",
        accessor: "countries_disallowed",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Thumbnail Image",
        accessor: "thumbnail_image",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Preview Image",
        accessor: "preview_image",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Featured Image",
        accessor: "featured_image",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Title Image",
        accessor: "title_image",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Duration",
        accessor: "duration",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Play After",
        accessor: "play_after",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Play Next After",
        accessor: "play_next_after",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Released Date",
        accessor: "released_date",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Released Year",
        accessor: "released_year",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Directors",
        accessor: "directors",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Cast",
        accessor: "cast",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Series id",
        accessor: "series_meta_series_id",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Seasons count",
        accessor: "series_meta_seasons_count",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Season id",
        accessor: "series_meta_season_id",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Episode id",
        accessor: "series_meta_episode_id",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Collection id",
        accessor: "collections_meta_collection_id",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Collection order id",
        accessor: "collections_meta_collection_order_id",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Liked",
        accessor: "liked",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Unliked",
        accessor: "unliked",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Played",
        accessor: "played",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Licence Termination",
        accessor: "licence_termination",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Licence Renewal",
        accessor: "licence_renewal",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Licence Renewal Reminder",
        accessor: "licence_renewal_reminder",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Added Date",
        accessor: "added_date",
        disableSortBy: true,
        disableFilters: true,
        width: 150,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Added Year",
        accessor: "added_year",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
    ],
    []
  );
};

const getMediaTagColumns = () => {
  return React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        width: 200,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Name",
        accessor: "name",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
    ],
    []
  );
};

const getMediaTagDisplayData = (items) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    const id = _.get(item, "id");

    return {
      name: _.get(item, "name"),
      id: <CopyItem text={id} />,
    };
  });
};

const getGenreColumns = () => {
  return React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        width: 200,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
      {
        Header: "Name",
        accessor: "name",
        disableSortBy: true,
        disableFilters: true,
        width: 100,
        className:
          "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
      },
    ],
    []
  );
};

const getGenreDisplayData = (items) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    const id = _.get(item, "id");

    return {
      name: _.get(item, "name"),
      id: <CopyItem text={id} />,
    };
  });
};

const getGenreListDataAsKeyValue = (items) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    return {
      value: _.get(item, "name"),
      key: _.get(item, "name"),
    };
  });
};

const getSearchMediaDisplayData = (items, handleRowClickView, viewAllDetails) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    const id = _.get(item, "id");
    const title = _.get(item, "title");
    const isFree = _.includes(_.get(item, "play_restrictions", []), 'NONE');

    return {
      id: <CopyItem text={id} />,
      uuid: <CopyItem text={_.get(item, "uuid")} />,
      media_uuid: <CopyItem text={_.get(item, "media_uuid")} />,
      primaryDetailsModal: (
        <div>
          <button
            onClick={() => handleRowClickView(item, 1)}
            className="pagination-button"
          >
            &nbsp;
            <span
              className="glyphicon glyphicon-folder-open"
              aria-hidden="true"
            />
          </button>
          <button
            onClick={() => viewAllDetails(item)}
            className="pagination-button"
          >
            &nbsp;
            <span
              className="glyphicon glyphicon-eye-open"
              aria-hidden="true"
            />
          </button>
          {isFree && <label style={{color: 'red'}}>FREE</label>}
        </div>
      ),
      imagesModal: (
        <button
          onClick={() => handleRowClickView(item, 2)}
          className="pagination-button"
        >
          &nbsp;
          <span
            className="glyphicon glyphicon-folder-open"
            aria-hidden="true"
          />
        </button>
      ),
      featuredModal: (
        <button
          onClick={() => handleRowClickView(item, 4)}
          className="pagination-button"
        >
          &nbsp;
          <span
            className="glyphicon glyphicon-folder-open"
            aria-hidden="true"
          />
        </button>
      ),
      metadataModal: (
        <button
          onClick={() => handleRowClickView(item, 3)}
          className="pagination-button"
        >
          &nbsp;
          <span
            className="glyphicon glyphicon-folder-open"
            aria-hidden="true"
          />
        </button>
      ),
      avodModal: (
        <button
          onClick={() => handleRowClickView(item, 5)}
          className="pagination-button"
        >
          &nbsp;
          <span
            className="glyphicon glyphicon-folder-open"
            aria-hidden="true"
          />
        </button>
      ),
      type: _.get(item, "type"),
      language: _.get(item, "language"),
      title: <CopyItem text={title} />,
      description: <TextAlign text={_.get(item, "description")} />,
      featured: _.get(item, "featured", false) ? "Yes" : "No",
      featured_from: getDisplayDate(_.get(item, "featured_from")),
      featured_to: getDisplayDate(_.get(item, "featured_to")),
      urls: <URLS urls={_.get(item, "urls")} dataType={"urls"} />,
      previews: <URLS urls={_.get(item, "previews")} dataType={"previews"} />,
      maturity_rating: _.get(item, "maturity_rating", "N/A"),
      max_quality: _.get(item, "max_quality", "N/A"),
      tags: <Tags tags={_.get(item, "tags")} />,
      genres: <Tags tags={_.get(item, "genres")} />,
      countries_allowed: <Tags tags={_.get(item, "countries_allowed")} />,
      countries_disallowed: <Tags tags={_.get(item, "countries_disallowed")} />,
      thumbnail_image: (
        <Images
          images={_.get(item, "thumbnail_image")}
          dataType={"thumbnail_image"}
        />
      ),
      preview_image: (
        <Images
          images={_.get(item, "preview_image")}
          dataType={"preview_image"}
        />
      ),
      featured_image: (
        <Images
          images={_.get(item, "featured_image")}
          dataType={"featured_image"}
        />
      ),
      title_image: (
        <Images images={_.get(item, "title_image")} dataType={"title_image"} />
      ),
      duration: _.get(item, "duration"),
      play_after: _.get(item, "play_after"),
      play_next_after: _.get(item, "play_next_after"),
      released_date: getDisplayDate(_.get(item, "released_date")),
      released_year: _.get(item, "released_year"),
      directors: <Tags tags={_.get(item, "directors")} />,
      cast: <Tags tags={_.get(item, "cast")} />,
      series_meta_series_id: (
        <GroupId id={_.get(item, "series_meta_series_id")} />
      ),
      series_meta_seasons_count: _.get(item, "series_meta_seasons_count"),
      series_meta_season_id: _.get(item, "series_meta_season_id"),
      series_meta_episode_id: _.get(item, "series_meta_episode_id"),
      collections_meta_collection_id: (
        <GroupId id={_.get(item, "collections_meta_collection_id")} />
      ),
      collections_meta_collection_order_id: _.get(
        item,
        "collections_meta_collection_order_id"
      ),
      liked: _.get(item, "liked", 0),
      unliked: _.get(item, "unliked", 0),
      played: _.get(item, "played", 0),
      licence_termination: getDisplayDate(_.get(item, "licence_termination")),
      licence_renewal: getDisplayDate(_.get(item, "licence_renewal")),
      licence_renewal_reminder: getDisplayDate(
        _.get(item, "licence_renewal_reminder")
      ),
      added_date: getDisplayDate(_.get(item, "added_date")),
      added_year: _.get(item, "added_year"),
    };
  });
};

const getDisplayCategoriesDisplayData = (items) => {
  if (_.isEmpty(items)) {
    return [];
  }

  return _.map(items, (item) => {
    const id = _.get(item, "id");

    return {
      name: _.get(item, "name"),
      id: <CopyItem text={id} />,
      language: _.get(item, "language"),
      type: _.get(item, "type"),
      order: _.get(item, "order"),
      active: _.get(item, "active", false) ? "true" : "false",
      tags: _.get(item, "tags"),
      genres: _.get(item, "genres"),
      countries_allowed: _.get(item, "countries_allowed"),
      countries_disallowed: _.get(item, "countries_disallowed"),
      is_branded_partner_rail: _.get(item, "is_branded_partner_rail"),
      branded_partner_uuid: _.get(item, "branded_partner_uuid"),
      rail_content_type: _.get(item, "rail_content_type"),
      play_restrictions: _.get(item, "play_restrictions"),
    };
  });
};

const getDisplayDate = (date, format = "MMM DD, YYYY hh:mm A") => {
  return date ? moment(date).format(format) : "N/A";
};

const getDeletableMedia = (mediaItems) => {
  const rows = _.map(mediaItems, (mediaItem, index) => {
    const renderedCells = _.reduce(
      mediaItem.original,
      (result, value, key) => {
        console.log("value : ", value);
        console.log("key : ", key);
        if (_.includes(["id", "language", "type", "title"], key)) {
          result.push(<td key={key}>{_.get(value, "props.text") || value}</td>);
        }
        return result;
      },
      []
    );

    return <tr key={index}>{renderedCells}</tr>;
  });

  const tableHeaders = (
    <thead>
      <tr>
        <th>Id</th>
        <th>Language</th>
        <th>Type</th>
        <th>Title</th>
      </tr>
    </thead>
  );

  return (
    <div>
      <table className={"table mp-content-v2-preview-table small"}>
        {tableHeaders}
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

const getDeletableShortMedia = (mediaItems) => {
  const rows = _.map(mediaItems, (mediaItem, index) => {
    const renderedCells = _.reduce(
      mediaItem.values,
      (result, value, key) => {
        console.log("value : ", value);
        console.log("key : ", key);
        if (_.includes(["uuid", "title"], key)) {
          result.push(<td key={key}>{_.get(value, "props.text") || value}</td>);
        }
        return result;
      },
      []
    );

    return <tr key={index}>{renderedCells}</tr>;
  });

  const tableHeaders = (
    <thead>
      <tr>
        <th>UUID</th>
        <th>Title</th>
      </tr>
    </thead>
  );

  return (
    <div>
      <table className={"table mp-content-v2-preview-table small"}>
        {tableHeaders}
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

const getDeletableDisplayCategories = (items) => {
  const rows = _.map(items, (item, index) => {
    const renderedCells = _.reduce(
      item.original,
      (result, value, key) => {
        console.log("value : ", value);
        console.log("key : ", key);
        if (_.includes(["id", "type", "name"], key)) {
          result.push(<td key={key}>{_.get(value, "props.text") || value}</td>);
        }
        return result;
      },
      []
    );

    return <tr key={index}>{renderedCells}</tr>;
  });

  const tableHeaders = (
    <thead>
      <tr>
        <th>Name</th>
        <th>Id</th>
        <th>Type</th>
      </tr>
    </thead>
  );

  return (
    <div>
      <table className={"table mp-content-v2-preview-table small"}>
        {tableHeaders}
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

const getDeletableMediaAccess = (items) => {
  const rows = _.map(items, (item, index) => {
    const renderedCells = _.reduce(
      item.original,
      (result, value, key) => {
        console.log("value : ", value);
        console.log("key : ", key);
        if (_.includes(["id", "sku_name", "sku_description"], key)) {
          result.push(<td key={key}>{_.get(value, "props.text") || value}</td>);
        }
        return result;
      },
      []
    );

    return <tr key={index}>{renderedCells}</tr>;
  });

  const tableHeaders = (
    <thead>
      <tr>
        <th>Name</th>
        <th>SKU Name</th>
        <th>SKU Description</th>
      </tr>
    </thead>
  );

  return (
    <div>
      <table className={"table mp-content-v2-preview-table small"}>
        {tableHeaders}
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

const getDeletableMediaTagAndGenre = (items) => {
  const rows = _.map(items, (item, index) => {
    const renderedCells = _.reduce(
      item.original,
      (result, value, key) => {
        console.log("value : ", value);
        console.log("key : ", key);
        if (_.includes(["id", "name"], key)) {
          result.push(<td key={key}>{_.get(value, "props.text") || value}</td>);
        }
        return result;
      },
      []
    );

    return <tr key={index}>{renderedCells}</tr>;
  });

  const tableHeaders = (
    <thead>
      <tr>
        <th>Name</th>
        <th>Id</th>
      </tr>
    </thead>
  );

  return (
    <div>
      <table className={"table mp-content-v2-preview-table small"}>
        {tableHeaders}
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

const getMediaIdsToDelete = (mediaItems) => {
  return _.map(mediaItems, "original.id.props.text");
};

const areFieldsFilled = (obj, optionalFields = []) => {
  const fieldsToCheck = Object.keys(obj).filter(
    (field) => !optionalFields.includes(field)
  );

  for (const field of fieldsToCheck) {
    if (!obj[field]) {
      return false;
    }
  }

  return true;
};

const getValuesFromRow = (row) => {
  const {
    active,
    countries_allowed,
    countries_disallowed,
    genres,
    id,
    language,
    name,
    order,
    tags,
    type,
    branded_partner_uuid,
    is_branded_partner_rail,
    rail_content_type,
    play_restrictions
  } = row;

  const convertedObject = {
    active,
    countries_allowed,
    countries_disallowed,
    genres,
    id: id.props.text,
    language,
    name,
    order,
    tags,
    type,
    branded_partner_uuid,
    is_branded_partner_rail,
    rail_content_type,
    play_restrictions
  };

  return convertedObject;
};

const getDate = (dateString) => {
  if (!dateString) {
    return null;
  }

  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const getUTCDateTime = (dateTimeString) => {
  if (!dateTimeString) {
    return null;
  }

  const date = new Date(dateTimeString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const getDateTime = (dateTimeString) => {
  if (!dateTimeString) {
    return null;
  }

  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

function incrementDateByDays(dateString, days) {
  // Parse the date string to create a Date object
  const date = new Date(dateString);

  // Increment the date by the specified number of days
  date.setDate(date.getDate() + days);

  // Format the new date back to the original format
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

const getValuesFromRowGenresAndTags = (row) => {
  const { id, name } = row;

  const convertedObject = {
    id: id.props.text,
    name,
  };

  return convertedObject;
};

const getMediaURLArrayV1 = (prefix, name, value, urlArray) => {
  let mediaURLArray = [];
  if (prefix === "1") {
    if (name === "urls") {
      mediaURLArray = [
        {
          ...URL_HLS,
          configs: HLS_CONFIGS,
          src: urlArray[0]?.src,
        },
        { ...URL_DASH, configs: DASH_CONFIGS, src: value },
      ];
    } else {
      mediaURLArray = [
        {
          ...URL_HLS,
          order: 1,
          src: urlArray[0]?.src,
        },
        { ...URL_DASH, order: 1, src: value },
      ];
    }
  } else {
    if (name === "urls") {
      mediaURLArray = [
        { ...URL_HLS, configs: HLS_CONFIGS, src: value },
        {
          ...URL_DASH,
          configs: DASH_CONFIGS,
          src: urlArray[1]?.src,
        },
      ];
    } else {
      mediaURLArray = [
        { ...URL_HLS, order: 1, src: value },
        {
          ...URL_DASH,
          order: 1,
          src: urlArray[1]?.src,
        },
      ];
    }
  }

  return mediaURLArray;
};

const getMediaURLArrayV1NoDRM = (prefix, value, urlArray) => {
  let mediaURLArray = [];
  if (prefix === "1") {
    mediaURLArray = [
      {
        ...URL_HLS,
        order: 1,
        src: urlArray[0]?.src,
      },
      { ...URL_DASH, order: 1, src: value },
    ];
  } else {
    mediaURLArray = [
      { ...URL_HLS, order: 1, src: value },
      {
        ...URL_DASH,
        order: 1,
        src: urlArray[1]?.src,
      },
    ];
  }

  return mediaURLArray;
};

const getMediaURLArrayWithoutDRM = (urlArray) => {
  let mediaURLArray = [];
  if (urlArray.length === 1) {
    mediaURLArray = [{...urlArray[0], configs: null}, {}];
  } else if (urlArray.length === 2) {
    mediaURLArray = [{...urlArray[0], configs: null}, {...urlArray[1], configs: null}];
  }
  
  return mediaURLArray;
};

const getMediaURLArrayWithDRM = (urlArray) => {
  let mediaURLArray = [];
  if (urlArray.length === 1) {
    mediaURLArray = [{...urlArray[0], configs: HLS_CONFIGS}, {}];
  } else if (urlArray.length === 2) {
    mediaURLArray = [{...urlArray[0], configs: HLS_CONFIGS}, {...urlArray[1], configs: DASH_CONFIGS}];
  }
  
  return mediaURLArray;
};

const getMediaUrlObjectByDestination = (destination, isShorts = false) => {
  const prefix = isShorts ? SHORTS_CDN_LOCATION : MEDIA_CDN_LOCATION;

  return [
    {
      ...URL_HLS,
      configs: HLS_CONFIGS,
      src: prefix + destination + '/' + HLS_FILE_NAME,
    },
    {
      ...URL_DASH,
      configs: DASH_CONFIGS,
      src: prefix + destination + '/' + DASH_FILE_NAME,
    },
  ];
};

const getLiveMediaUrlObject = (hlsUrl) => {
  return [
    {
      ...URL_HLS,
      configs: HLS_CONFIGS,
      src: hlsUrl,
    },
  ];
};

const getPreviewUrlObjectByDestination = (destination, isShorts = false) => {
  const prefix = isShorts ? SHORTS_CDN_LOCATION : MEDIA_CDN_LOCATION;

  return [
    {
      ...URL_HLS,
      configs: null,
      order: 1,
      src: prefix + destination+ '/'  + HLS_FILE_NAME,
    },
    {
      ...URL_DASH,
      configs: null,
      order: 1,
      src: prefix + destination+ '/'  + DASH_FILE_NAME,
    },
  ];
};

const convertToSelectObject = (value) => {
  if (!value) {
    return null;
  }

  return {
    label: value,
    value: value,
  };
};

export {
  getMediaItems,
  getMediaCopyItems,
  getDeletableMedia,
  getUploadableFiles,
  getDisplayCopyItems,
  getMediaIdsToDelete,
  getMediaItemsColumns,
  getSupportedLanguages,
  getSupportedImageTypes,
  getSupportedImageSizes,
  getDisplayCategoryItems,
  getDisplayCategoryColumns,
  getSupportedEnvironments,
  getSearchMediaDisplayData,
  getMediaTagColumns,
  getMediaTagDisplayData,
  getGenreColumns,
  getGenreDisplayData,
  getDisplayCategoriesDisplayData,
  convertArrayToObjMap,
  areFieldsFilled,
  getValuesFromRow,
  getMediaItemsColumnsV2,
  getDate,
  getGenreListDataAsKeyValue,
  getCountryCodesAsKeyValuePair,
  getArrayFromString,
  getValuesFromRowGenresAndTags,
  getDeletableMediaTagAndGenre,
  getDeletableDisplayCategories,
  getDeletableMediaAccess,
  convertArrayToObjMapWithLowerCase,
  getSupportedImageTypesV2,
  convertArrayToSelectList,
  getCountryCodesAsLabelValuePair,
  convertArrayToSelectMediaAccessList,
  convertArrayToSelectListV2,
  convertArrayToSelectListV3,
  getMediaUrlObjectByDestination,
  getPreviewUrlObjectByDestination,
  convertToSelectObject,
  getDateTime,
  getUTCDateTime,
  getMediaURLArrayV1,
  convertArrayToObjMapWithKey,
  getQalboxShortColumns,
  getDeletableShortMedia,
  getMediaURLArrayWithoutDRM,
  getMediaURLArrayWithDRM,
  getMediaURLArrayV1NoDRM,
  incrementDateByDays,
  getLiveMediaUrlObject,
};
