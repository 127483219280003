import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';

export const useUpdateMediaAccess = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateMediaAccessHook = async (media_access, handleUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosService.put(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}qalbox-admin-media-access/v1/update/`,
        {media_access}
      );

      const json = response?.data;
      if (json?.payload) {
        setSuccess(true);
        handleUpdate(json?.payload);
      } else {
        setError("Failed to update media access");
      }
      
    } catch (error) {
      setError("An error occurred while updating the media access");
    } finally {
      setLoading(false);
    }
  };

  return [ updateMediaAccessHook, loading, error, success ];
};