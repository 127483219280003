import { useState } from "react";
import axiosService from "../../../../service_v2/common/axios-service";
import {MP_CF_QALBOX_UPDATE_STREAMER} from "../../../../Constants";

export const useUpdateStreamer = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateStreamerHook = async (streamer, handleUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosService.put(
        `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${MP_CF_QALBOX_UPDATE_STREAMER}`,
        { ...streamer }
      );

      const json = response?.data;
      if (json?.payload) {
        setSuccess(true);
        handleUpdate(json?.payload);
      } else {
        setError("Failed to update streamer");
      }
    } catch (error) {
      setError("An error occurred while updating the streamer");
    } finally {
      setLoading(false);
    }
  };

  return [updateStreamerHook, loading, error, success];
};
