import _ from "lodash";
import React, { useEffect, useState } from "react";
import { QalboxTextInput } from "../helperComponents/qalboxTextInput";
import { QalboxInputWrapper } from "../helperComponents/qalboxInputWrapperComponent";
import { useCreateStreamer } from "./hooks/useCreateStreamer";
import { useUpdateStreamer } from "./hooks/useUpdateStreamer";
import QalboxImageUploader from "../media/helperComponents/qalboxImageUploader";
import TextWarning from "../warnings/TextWarning";
import { handleValidation } from "./validations/streamerValidation";


const initialAdUnitbject = {
  id:"",
  streamer_name: "",
  streamer_logo: "",
  streamer_desc: "",
};

export const CreateStreamerModal = ({ data, handleUpdates }) => {
  const [streamer, setStreamer] = useState(initialAdUnitbject);
  const [streamerWarning, setStreamerWarning] = useState(initialAdUnitbject);
  const [createStreamerHook] = useCreateStreamer();
  const [updateStreamerHook] = useUpdateStreamer();

  const handleInputTextChange = (e) => {
    let { name, value } = e.target;

    setStreamer((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setStreamerWarning((prevState) => ({
      ...prevState,
      [name]: "",
    }));

  };

  const handleOnSave = () => {
    if (handleValidation(initialAdUnitbject,streamer,setStreamerWarning)) {
      createStreamerHook(streamer, handleUpdates);
    }
  };
  
  const handleOnUpdate = () => {
    if (handleValidation(initialAdUnitbject,streamer,setStreamerWarning)) {
      updateStreamerHook(streamer, handleUpdates);
    }
  };
  

  useEffect(() => {
    if (data) {
      setStreamer(data);
    }
  }, [data]);

  return (
    <div>
      <QalboxTextInput
        label="Streamer Name"
        name="streamer_name"
        value={streamer.streamer_name}
        onChange={handleInputTextChange}
      />
      <TextWarning message={streamerWarning.streamer_name} style={{marginTop:'-12px'}} />

      <label>Streamer Logo</label>
      <QalboxImageUploader
        name={"streamer_logo"}
        value={streamer.streamer_logo}
        handleInputTextChange={handleInputTextChange}
      />
      <TextWarning message={streamerWarning.streamer_logo} />

      <label>Streamer Description</label>
      <textarea
        rows={5}
        className="form-control"
        name="streamer_desc"
        value={streamer.streamer_desc}
        onChange={handleInputTextChange}
      />
      <TextWarning message={streamerWarning.streamer_desc} />

      <QalboxInputWrapper
        style={{ marginTop: "5px" }}
        component={
          data ? (
            <button className="pagination-button" onClick={handleOnUpdate}>
              Update
            </button>
          ) : (
            <button className="pagination-button" onClick={handleOnSave}>
              Save
            </button>
          )
        }
      />
    </div>
  );
};
