import _ from "lodash";

export const handleValidation = (initialAdUnitbject,streamer,setStreamerWarning) => {
    let isValid = true;
    let warnings = { ...initialAdUnitbject };
    
    // Iterate over each field in the streamer object
    _.map(streamer, (value, key) => {
      if (_.isEmpty(value) && key !== 'id') {
        warnings[key] = `${_.startCase(key.replace(/_/g, ' '))} is required!`;
        isValid = false;
      }
    });
  
    setStreamerWarning(warnings);
    return isValid;
  };
  