import _ from "lodash";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row } from "react-bootstrap";
import BasicLayout from "../../../layouts/basiclayout";
import { confirmAlert } from "react-confirm-alert";
import AppModal from "../../../widgets/modal";
import { ScaleLoader } from "react-spinners";
import QalboxTable from "../../../widgets/qalbox-table";
import { useFetchStreamers } from "./hooks/useFetchStreamers";
import { useDeleteStreamer } from "./hooks/useDeleteStreamer";
import { CreateStreamerModal } from "./createStreamer";
import { getStreamerColumns } from "./helpers/streamerColumns";
import { getStreamerDisplayData } from "./helpers/streamerDataTransform";
import { getDeletableStreamer, getSyncAbleStreamer } from "./helpers/deletableStreamer";
import { useProdSyncStreamer } from "./hooks/useProdSyncStreamer";

const QalboxStreamers = () => {
  const [refresh, setRefresh] = useState(false);
  const [showStreamerModal, setShowStreamerModal] = useState(false);
  const [streamer, setStreamer] = useState(null);
  const [streamerResponse, isLoadingStreamer, errorFetching] =
    useFetchStreamers(refresh);
  const [deleteStreamer, isDeleting, isDeletingError] = useDeleteStreamer();
  const [prodSyncStreamer] = useProdSyncStreamer();


  const onDeleteSelected = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to delete the following ${_.size(
              selectedFlatRows
            )} streamer items ?`}
            modalBody={getDeletableStreamer(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Delete"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              const uuidListToDelete = _.map(
                selectedFlatRows,
                "values.id.props.text"
              );
              _.forEach(uuidListToDelete, (id) => {
                deleteStreamer(id, handleRefresh);
              });

              alert("Successfully Deleted !!!");
              handleRefresh();
              resetSelectedRows();
            }}
          />
        );
      },
    });
  };

  const onEditClicked = (selectedFlatRows, resetSelectedRows) => {
    const id = _.get(selectedFlatRows, "[0].values.id.props.text", null);
    const streamerItem = streamerResponse.find((item) => item.id === id);
    delete streamerItem.updated_ts;
    setStreamer(streamerItem);
    setShowStreamerModal(true);
    resetSelectedRows();
  };

  const onProdSyncClicked = (selectedFlatRows, resetSelectedRows) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AppModal
            show={true}
            showBody={true}
            headingText={`Are you sure want to sync to production the following ${_.size(
              selectedFlatRows
            )} streamer items ?`}
            modalBody={getSyncAbleStreamer(selectedFlatRows)}
            modalBodyClassName={"precalcs-diff-merge-view"}
            closeButtonText={"No"}
            okButtonText={"Yes, Sync to Production"}
            handleClose={onClose}
            handleOk={() => {
              onClose();
              const idListToSync = _.map(selectedFlatRows, "values.id.props.text");
              const streamersToSync =  _.filter(streamerResponse, item => _.includes(idListToSync, item.id));
              handleOnProdSync(streamersToSync, resetSelectedRows);
            }}
          />
        );
      },
    });
  };

  const handleOnProdSync = (shorts, resetSelectedRows) => {
    prodSyncStreamer(shorts, resetSelectedRows);
  };

  const handleRefresh = () => {
    console.log(refresh)
    setRefresh((prevRefresh) => !prevRefresh);
  };

  const onUpdate = () => {
    setShowStreamerModal(false);
    handleRefresh();
  };

  const handleCreate = () => {
    setStreamer(null);
    setShowStreamerModal(true);
  };

  return (
    <LoadingOverlay
      active={isLoadingStreamer || isDeleting}
      spinner={<ScaleLoader />}
      text={
        isDeleting
          ? "Deleting the selected streamer items."
          : "Loading streamer..."
      }
    >
      <BasicLayout pagePermission="moderate_precalc">
        <ToastContainer autoClose={2000} />
        <Row>
          <Col md={9}>
            <h3 className="content_editor_title">{"Qalbox streamer"}</h3>
          </Col>
          <Col md={3} style={{ paddingTop: "20px" }}>
            <button
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                textDecoration: "none",
                borderRadius: "4px",
              }}
              onClick={handleCreate}
            >
              Create
            </button>
          </Col>
        </Row>
        <Row></Row>
        <QalboxTable
          table={{
            data: getStreamerDisplayData(streamerResponse),
            columns: getStreamerColumns(),
            handleDelete: onDeleteSelected,
            handleEdit: onEditClicked,
            isLoading: isLoadingStreamer,
            tableError: errorFetching,
            handleProdSync: process.env.REACT_APP_IS_PROD_SYNC_AVAILABLE
              ? onProdSyncClicked
              : null,
          }}
        />
      </BasicLayout>
      <AppModal
        show={showStreamerModal}
        showBody={true}
        headingText={`Create Streamer`}
        modalBody={
          <CreateStreamerModal data={streamer} handleUpdates={onUpdate} />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => {
          setShowStreamerModal(false);
          setStreamer(null);
        }}
      />
    </LoadingOverlay>
  );
};

export default QalboxStreamers;
