import axios from 'axios';
import {MP_CF_API_BASE_URL} from "../../apis/Constants";

const getBaseConfig = (idToken) => {
  const headers = {
    'idToken': idToken || '',
    'uid': localStorage.getItem('uid') || '',
    'Content-Type': 'application/json',
  };

  if (idToken) {
    headers['Authorization'] = `Bearer ${idToken}`;
  }

  return {
    baseURL: MP_CF_API_BASE_URL,
    headers,
  };
}

export default axios.create({
  ...getBaseConfig(localStorage.getItem('idToken')),
  responseType: 'json'
} )
