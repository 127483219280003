import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AppModal from "../../../../widgets/modal";
import { gcsImageUploadHook } from "../../../../hooks";
import { getUploadableFiles } from "../../helper";
import LoadingOverlay from "react-loading-overlay";
import { ScaleLoader } from "react-spinners";

const QalboxImageUploader = ({ name, value, handleInputTextChange }) => {
  const [isLoading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgUrl, setImgUrl] = useState('');
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [ uploadData, doUpload ] = gcsImageUploadHook();

  const onOperationComplete = (response) => {
    const imageUrl = _.get(response, 'data[0].public_url', null);
    setImgUrl(imageUrl);
    handleInputTextChange({target: {name: name, value: imageUrl}});
    
    if (!imageUrl) {
      alert('upload failed');
    }

    setLoading(false);
  }

  const uploadImages = async (file) => {
    setLoading(true);
    const {
      uploadableFiles,
    } = await getUploadableFiles({files: [file], selectedImageSize: 'small', selectedLanguage: 'en', selectedImageType: 'first_frame'});

    doUpload({
      files: uploadableFiles,
      clearCdnCache: null,
      optimizeImages: null,
      resizeImages: null,
      resizeImageSizes: null,
      bucketName: null,
      project: null,
      onOperationComplete,
    });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    await uploadImages(file);
    setSelectedImage(file);
  };

  useEffect(() => {
    if (value) {
      setImgUrl(value);
    }
  }, [value])
  
  return (
    <LoadingOverlay
      active={isLoading}
      spinner={<ScaleLoader />}
      text={"Loading..."}
    >
    <div className="qalbox-form-row">
      <textarea
        value={imgUrl}
        name={name}
        type="text"
        onChange={handleInputTextChange}
        className="form-control"
        placeholder={name}
        style={{ marginBottom: "5px", marginRight: "10px" }}
      />
      <input
        style={{maxWidth:'200px'}}
        type="file"
        accept="image/*"
        onChange={handleImageChange}
      />
      <CopyToClipboard text={imgUrl}>
        <Button className="btn btn-xs btn-dark mp-content-v2-table-hover-btn">
          &nbsp;
          <span className="glyphicon glyphicon-duplicate" aria-hidden="true" />
        </Button>
      </CopyToClipboard>
      <Button
        style={{ marginRight: "5px" }}
        className="btn btn-xs btn-dark mp-content-v2-table-hover-btn"
        onClick={() => setShowImagePreview(true)}
      >
        <span className="glyphicon glyphicon-picture" aria-hidden="true" />
      </Button>
      <AppModal
        show={showImagePreview}
        showBody={true}
        headingText={`Image Preview`}
        modalBody={
          <img
            src={imgUrl} // Replace with the actual path to your image
            alt="Image Preview"
            style={{ maxWidth: "100%", marginTop: "10px" }}
          />
        }
        modalBodyClassName={""}
        closeButtonText={""}
        okButtonText={""}
        handleClose={() => setShowImagePreview(false)}
      />
    </div>
    </LoadingOverlay>
  );
};

export default QalboxImageUploader;