import React from "react";

export const getStreamerColumns = () => {
    return React.useMemo(
      () => [
        {
          Header: "Id",
          accessor: "id",
          disableSortBy: true,
          disableFilters: true,
          width: 100,
          className:
            "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
        },
        {
          Header: "Name",
          accessor: "streamer_name",
          disableSortBy: false,
          disableFilters: false,
          width: 150,
          className:
            "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
        },
  
        {
          Header: "Logo",
          accessor: "streamer_logo",
          disableSortBy: false,
          disableFilters: false,
          width: 200,
          className:
            "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
        },
        {
          Header: "Description",
          accessor: "streamer_desc",
          disableSortBy: false,
          disableFilters: true,
          width: 200,
          className:
            "mp-content-v2-table-content-title mp-content-v2-table-td-pt text-center",
        },
      ],
      []
    );
  };