import _ from "lodash";
import { CopyItem } from "../../common";

export const getStreamerDisplayData = (items) => {
    if (_.isEmpty(items)) {
      return [];
    }
  
    return _.map(items, (item) => {
      const id = _.get(item, "id");
      const logo = _.get(item, "streamer_logo")
  
      return {
        streamer_name: _.get(item, "streamer_name"),
        streamer_logo: <img src={logo} alt={"streamer logo"} width={64} className="align-self-center" />,
        streamer_desc: _.get(item, "streamer_desc"),
        id: <CopyItem text={id} />,
      };
    });
  };