import { useState } from 'react';
import axiosService from '../../../../service_v2/common/axios-service';
import {MP_CF_QALBOX_SYNC_STREAMER} from "../../../../Constants";
import { toast } from 'react-toastify';

export const useProdSyncStreamer = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const prodSyncStreamer = async (streamers, handleUpdate) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosService.post(
        `${process.env.REACT_APP_PROD_SYNC_URL}${MP_CF_QALBOX_SYNC_STREAMER}`,
        { data: streamers }
      );

      if (response?.data) {
        setSuccess(true);
        handleUpdate(response?.data);
        toast.success(`Selected streamers synced to production successfully.`, {
          position: toast.POSITION.BOTTOM_LEFT
        });     
      } else {
        setError("Failed to sync the Streamer to production");
        toast.error(`Failed to sync the Streamer to production`, {
          position: toast.POSITION.BOTTOM_LEFT
        }); 
      }
    } catch (error) {
      setError("An error occurred while syncing the Streamer to production");
      toast.error(`Failed to sync the Streamer to production. Try again.`, {
        position: toast.POSITION.BOTTOM_LEFT
      }); 
    } finally {
      setLoading(false);
    }
  };

  return [ prodSyncStreamer, loading, error, success ];
};