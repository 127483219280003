import _ from "lodash";

export const getDeletableStreamer = (items) => {
    const rows = _.map(items, (item, index) => {
      const renderedCells = _.reduce(
        item.original,
        (result, value, key) => {
          if (
            _.includes(
              ["id", "streamer_name", "streamer_logo", "streamer_desc"],
              key
            )
          ) {
            const textValue = _.get(value, "props.text") || value;
           
              result.push(<td key={key}>{textValue}</td>);
  
          }
          return result;
        },
        []
      );
  
      return <tr key={index}>{renderedCells}</tr>;
    });
  
    const tableHeaders = (
      <thead>
        <tr>
          <th>Streamer Name</th>
          <th>Streamer Logo</th>
          <th>Streamer Description</th>
          <th>Id</th>
        </tr>
      </thead>
    );
  
    return (
      <div>
        <table className={"table mp-content-v2-preview-table small"}>
          {tableHeaders}
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  };

  export const getSyncAbleStreamer = (items) => {
    const rows = _.map(items, (item, index) => {
      const renderedCells = _.reduce(
        item.values,
        (result, value, key) => {
          if (_.includes(["id", "streamer_name"], key)) {
            result.push(<td key={key}>{_.get(value, "props.text") || value}</td>);
          }
          return result;
        },
        []
      );
  
      return <tr key={index}>{renderedCells}</tr>;
    });
    const tableHeaders = (
      <thead>
        <tr>
          <th>ID</th>
          <th>Streamer Name</th>
        </tr>
      </thead>
    );
  
    return (
      <div>
        <table className={"table mp-content-v2-preview-table small"}>
          {tableHeaders}
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
  