import { useState, useEffect } from "react";
import axiosService from "../../../../service_v2/common/axios-service";
import {MP_CF_QALBOX_FETCH_STREAMER} from "../../../../Constants";

export const useFetchStreamers = (refresh) => {
  const [streamers, setStreamers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStreamers = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axiosService.get(
          `${process.env.REACT_APP_MP_CF_GEN2_API_BASE_URL}${MP_CF_QALBOX_FETCH_STREAMER}`
        );

        setStreamers(response?.data?.payload);
      } catch (err) {
        console.error(err);
        setError(err.message || "An error occurred while fetching ad units");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStreamers();
  }, [refresh]); // Fetch streamer whenever the 'active' parameter changes

  return [streamers, isLoading, error];
};
